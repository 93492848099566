// import { goodsType, formatGoodsType } from "@/utils/type";

export const columns = [ 
  {
    title: 'SKU编码',
    dataIndex: 'goods_sku_number',
    align: 'center'
  },
  {
    title: '添加时间',
    dataIndex: 'add_time',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    slots: { customRender: 'operation' },
    align: 'center'
  },
]

export default {
  columns,
  // goodsType
}
