<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :wrapperCol="{ span: 18 }"
        :labelCol="{ span: 6 }"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="goods_sku_number" label="SKU码">
              <a-input
                allowClear
                v-model="searchForm.goods_sku_number"
                placeholder="请输入SKU码"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <div class="text-right mt-1">
              <a-button @click="resetForm"
                >重置</a-button
              >
              <a-button class="ml-4" @click="initData" type="primary" html-type="submit"
                >搜索</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="tableRef"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :row-selection="rowSelection"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between mb-2">
          <div class="flex items-center">
            <h4 class="text-lg font-bold mb-0">快递商品SKU码</h4>
            <div>（以下sku码的商品由旺店通发货）</div>
          </div>
          
          <div>
            <a-button :disabled="!rowSelection.selectedRowKeys.length" 
                @click="batchDel">批量删除</a-button>
            <a-button class="ml-4" type="primary" html-type="submit"
                @click="skuAdd()">批量添加SKU码</a-button>
          </div>
        </div>
      </template>
      <template #operation="{ record }">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="skuDel(record)"
          >删除</a-button
        >
      </template>
    </base-table>
    <sku-modal
      v-if="isShowModal"
      :show.sync="isShowModal"
      :activeRow="activeRow"
      @ok="initData"
    />
  </div>
</template>

<script>
import columns from "./columns";
import skuModal from "./components/sku-modal.vue";

import { getNotCreateGoodsSkuNumberList, deleteNotCreateGoodsSkuNumber } from '@/api/sku-list';
export default {
  components: { skuModal },

  data() {
    return {
      ...columns,
      total: 0,
      tableData: [],
      rowKey: "goods_sku_number",
      isShowModal: false,
      activeRow: {},
      searchForm: {
        goods_sku_number: "",
        page: 1,
        page_count: 20,
      },

      rowSelection: {
        skuList:[],
        selectedRowKeys: [],
        onChange(keys) {
          this.selectedRowKeys = keys
        }
      },
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    async initData() {
      this.rowSelection.selectedRowKeys = []
      const {code,data} = await getNotCreateGoodsSkuNumberList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    skuAdd() {
      this.isShowModal = true
    },

    // 删除
    batchDel(){
      this.deleteSkus(this.rowSelection.selectedRowKeys)
    },
    skuDel(row) {
      this.deleteSkus([row.goods_sku_number])
    },

    async deleteSkus(skus){
      if(!skus.length) return

      const self = this
      this.$confirm({
        title: skus.length>1 ? "删除所选的SKU码？" : "删除此SKU码？",
        icon: "",
        onOk: async () => {
          console.log(skus.join(','))
          const {code} = await deleteNotCreateGoodsSkuNumber({
            goods_sku_number: skus.join(',')
          })
          if(code == 0){
            self.rowSelection.selectedRowKeys = []
            self.$message.success("已删除")
            self.initData()
          }
        },
      });
    },
    resetForm() {
      this.searchForm = {
        goods_sku_number: "",
        page: 1,
        page_count: 20,
      };
      this.initData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>