<template>
  <div>
    <a-modal
      :visible.sync="show"
      :title="title"
      destroyOnClose
      width="600px"
      @ok="handleOk"
      @cancel="$emit('update:show', false)"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item style="margin-bottom: 10px" label="SKU编码">
          <a-input
            v-model="forms.goods_sku_number"
            type="textarea"
            :autoSize="{ minRows: 10 }"
            placeholder="请输入SKU编码；每行为一个SKU码"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { addNotCreateGoodsSkuNumber } from '@/api/sku-list';

export default {
  props: {
    show: {
      type: Boolean,
    },
    activeRow: {
      type: Object,
    },
  },
  data() {
    return {
      title: "添加SKU",
      forms: {
        goods_sku_number:""
      },
    };
  },

  mounted() {
  },

  methods: {
    async handleOk() {
      var SKUArr = this.forms.goods_sku_number.split('\n')
      SKUArr = SKUArr.map(item => {
        return item.replace(/\s*/g,"")
      })
      SKUArr = SKUArr.filter((s) => {
        return s && s.trim();
      });
      var newArr = SKUArr.filter((item,index)=> {return SKUArr.indexOf(item,0) === index});
      const param = {
        goods_sku_number:newArr.join(',')
      }
      const {code} = await addNotCreateGoodsSkuNumber(param)
      if(code == 0){
        this.$message.success('添加成功！')
        this.$emit('ok')
        this.$emit('update:show', false)
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>