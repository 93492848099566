import ajax from "@/utils/ajax.js";

/////////////////////SKU列表////////////////////
/**
 * 获取SKU列表
 * @param {*} params
 * @returns
 */
export function getNotCreateGoodsSkuNumberList(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/getNotCreateGoodsSkuNumberList", params);
}

/**
 * 添加SKU
 * @param {*} params
 * @returns
 */
export function addNotCreateGoodsSkuNumber(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/addNotCreateGoodsSkuNumber", params);
}

/**
 * 删除SKU
 * @param {*} params
 * @returns
 */
export function deleteNotCreateGoodsSkuNumber(params) {
  return ajax.post("/CustomerServiceOtherOrderOther/deleteNotCreateGoodsSkuNumber", params);
}




